import qs from 'qs'

const fields = [
  'title',
  'richText',
  'locale'
]
export const queryNewsletterPage = (options: queryOptions) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      fields,
      populate: {
        audiences: '*',
        localizations: {
          fields,
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `newsletter-page?${query}`
}
